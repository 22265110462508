import './App.scss'
import './assets/css/hover.css'
import 'video-react/dist/video-react.css';
import React, { useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Switch,
} from 'react-router-dom';


import { useDispatch } from 'react-redux';

const loading = () => <div className="animated fadeIn pt-3 text-center loading-message">Loading...</div>;

// Containers
const HowGlimpseWorksPage = React.lazy(() => import('./pages/HowGlimpseWorksPage'));
const HowGlimpseWorksProfessionalsPage = React.lazy(() => import('./pages/HowGlimpseWorksProfessionalsPage'));
const ReferralPage = React.lazy(() => import('./pages/ReferralPage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const AdminAuth = React.lazy(() => import('./pages/AdminAuth'));
const ForgotPasswordPage = React.lazy(() => import('./pages/ForgotPasswordPage'));
const SignupPage = React.lazy(() => import('./pages/SignupPage'));
const EnrollPage = React.lazy(() => import('./pages/EnrollPage'));
const AdminDashboard = React.lazy(() => import('./pages/AdminDashboard'));
const RequestsPage = React.lazy(() => import('./pages/RequestsPage'));
const LandingPageNew = React.lazy(() => import('./NewPages/LandingPageNew'));
const DetailsPageNew = React.lazy(() => import('./NewPages/DetailsPageNew'))

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    
    var isUserLoggedInLocal = localStorage.getItem('isUserLoggedIn');

    if (isUserLoggedInLocal != null) {
      dispatch({type:"SET_ISUSERLOGGEDIN",payload:isUserLoggedInLocal})
    }

    let userData = JSON.parse(localStorage.getItem('UserData'))
    if(userData != null){
      dispatch({type:"SET_USERID",payload:userData.Id})
      dispatch({type:"SET_USERDATA",payload:userData})
    }
  })
  
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/" name="Home" render={props => <LandingPageNew {...props}/>} />
          <Route exact path="/how-glimpse-works" name="How does glimpse work" render={props => <HowGlimpseWorksPage {...props}/>} />
          <Route exact path="/login" name="Login page for the professionals dashboard" render={props => <LoginPage {...props}/>} />
          <Route exact path="/adminauth" name="Login page for the Admin's to login as professionals" render={props => <AdminAuth {...props}/>} />
          <Route exact path="/forgot-password" name="Page to reset the password for the professionals dashboard" render={props => <ForgotPasswordPage {...props}/>} />
          <Route exact path="/signup" name="Signup page for the professionals dashboard" render={props => <SignupPage {...props}/>} />
          <Route exact path="/how-referral-works/professionals" name="Referral page for professionals" render={props => <ReferralPage {...props}/>} />
          <Route exact path="/how-glimpse-works/professionals" name="How does glimpse work for professionals" render={props => <HowGlimpseWorksProfessionalsPage {...props}/>} />
          <Route exact path="/enroll" name="Enrollment Page for the professionals" render={props => <EnrollPage {...props}/>} />
          <Route exact path="/admin" name="Admin dashboard for the professionals" render={props => <AdminDashboard {...props}/>} />
          <Route exact path="/admin/requests/:id" name="Requests page for admin dashboard" render={props => <RequestsPage {...props}/>} />
          <Route exact path="/:name" name="Details page of each professionals" render={props => <DetailsPageNew {...props}/>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
